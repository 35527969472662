import Alert from '@mui/material/Alert';
import React, { useContext, useEffect } from 'react';
import CartContext from '../../../../contexts/CartContext';
import ConfigContext from '../../../../contexts/ConfigContext';
import ModifyCartPanel from '../../cart/ModifyCartPanel';
import PhoneIcon from '@mui/icons-material/Phone';
import API from '../../../../API';

type Props = {
  setNextName: React.Dispatch<React.SetStateAction<string>>;
  setPreNextAction: React.Dispatch<React.SetStateAction<() => () => Promise<boolean>>>;
}

const Step1 = ({setNextName, setPreNextAction}: Props) => {
  const {cart, setCartState} = useContext(CartContext);
  const {config} = useContext(ConfigContext);

  const items: ICartItem[] = Object.values(cart.items);
  const specialOrder = items.filter((item) => !!item.item.special).length > 0;

  useEffect(() => {
    const validateCart = async () => {
      const newItems: Record<string, ICartItem> = {};
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const remoteItem = await API.getItemById(item.item.id);
        item.item.inventory = remoteItem.inventory;
        newItems[item.item.id] = item;
      }

      setCartState({
        ...cart,
        items: newItems
      })
    }

    setPreNextAction(() => async () => {return !specialOrder});
    setNextName("Next");
    validateCart();
    window.scrollTo(0, 0);
  }, []);

  // When we restart the payment process, clear the last error
  cart.lastError = undefined;

  if (specialOrder) {
    return (
      <div className='checkout-parent'>
        <div className='checkout-main'>
          <Alert
            iconMapping={{
              info: <PhoneIcon fontSize="inherit" />,
            }}
            severity="info" className='margin-top'>
              <div className='fast-shipping-description'>
                One or more items are available by <b>Special Order</b> only.
              </div>
              <div>
                <b>Special Order</b> items may include items that are particularly heavy or large. They
                might also include items that require special handling or information. Please call at
                (902) 343-3569 to submit special orders.
              </div>
            </Alert>

          <ModifyCartPanel checkout={true} hideDiscount={true} hideTax={true} hideTotal={true} hideInactive={true}/>
        </div>
      </div>
    )
  }

  return (
    <div className='checkout-parent'>
      <div className='checkout-main'>
        <ModifyCartPanel checkout={true} hideDiscount={true} hideTax={true} hideTotal={true} hideInactive={true}/>
      </div>
    </div>
  )

}

export default Step1